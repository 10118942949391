<template>
    <div>
        <!-- Breadcrumb start -->
        <section class="bradcrumb_sec">
            <div class="container-fluid custome_container">
                <div class="row justify-content-center">
                    <div class="col-md-11">
                        <div class="bread_crumb_box mb_22">
                            <h2 class="heading_1 font_bold mb_23">Bench Work</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0"
                                            :to="{ name: 'user_home' }">
                                            Home
                                        </router-link></li>
                                    <li class="breadcrumb-item">
                                        <router-link :to="{ name: 'work_from_lab' }">Working From the Lab</router-link>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">Bench Work</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Breadcrumb end -->
        <!-- pagination Starts -->
        <section class="pagination_sec">
            <div class="pagination-nav">
                <div class="container-fluid custome_container">
                    <div class="row justify-content-center">
                        <div class="col-md-11">
                            <div class="row justify-content-between">
                                <div class="col-md-12">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-between mb_32">
                                            <li class="page-item">
                                                <router-link class="d-none page-link border-0 text_black"
                                                    :to="{ name: 'work_from_lab' }" aria-label="Previous">
                                                    <span aria-hidden="true">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="29.631"
                                                            viewBox="0 0 43.575 29.631">
                                                            <g id="Left_arrow" data-name="Left arrow"
                                                                transform="translate(2.75 3.967)">
                                                                <path id="Path_30" data-name="Path 30"
                                                                    d="M1755.8,353.578l-10.914,10.889,10.937,10.965"
                                                                    transform="translate(-1744.886 -353.656)" fill="none"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    stroke-width="5.5" />
                                                                <path id="Path_31" data-name="Path 31"
                                                                    d="M1755.8,353.578l-10.914,10.889,10.937,10.965"
                                                                    transform="translate(-1728.886 -353.656)" fill="none"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    stroke-width="5.5" />
                                                            </g>
                                                        </svg>
                                                        Previous
                                                    </span>
                                                </router-link>
                                            </li>
                                            <li class="page-item">
                                                <router-link class="page-link border-0 text_black"
                                                    :to="{ name: 'pipetting' }" aria-label="Next">
                                                    <span aria-hidden="true" class="font_bold">
                                                        <span class="text_primary">Up Next<span
                                                                class="mobile_hide">:</span></span>
                                                        <span class="mobile_hide"> Pipetting</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="33.575"
                                                            height="29.631" viewBox="0 0 33.575 29.631"
                                                            style="margin-left:5px;">
                                                            <g id="Right_arrow" data-name="Right arrow"
                                                                transform="translate(-12884.354 20078.389)">
                                                                <path id="Path_30" data-name="Path 30"
                                                                    d="M1744.908,353.578l10.914,10.889-10.937,10.965"
                                                                    transform="translate(11159.357 -20428.078)" fill="none"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    stroke-width="5.5" />
                                                                <path id="Path_31" data-name="Path 31"
                                                                    d="M1744.908,353.578l10.914,10.889-10.937,10.965"
                                                                    transform="translate(11143.357 -20428.078)" fill="none"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    stroke-width="5.5" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- pagination Ends -->
        <!-- image text start -->
        <section class="image_text_sec bg_grey pt_50 pb_50 mt_30">
            <div class="container-fluid custome_container">
                <div class="row justify-content-center">
                    <div class="col-md-11">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="image_left_side">
                                    <img src="../assets/images/work_from_lab/bench-mark.png" alt="img">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="image_text_right_side">
                                    <h2 class="mt_10 mb_22">Are you feeling your best? </h2>
                                    <p class="text_black mb_40">
                                        Anyone who works at a lab bench understands the multitude and complexity of tasks
                                        involved. Good ergonomics plus good techniques in the lab will boost your energy
                                        while
                                        helping to stave off unwanted aches and pains.


                                    </p>
                                    <p class="text_black mb_20">
                                        Here are some guidelines to help you get your ergo on!

                                    </p>
                                    <div class="">
                                        <a href="#" class="btn btn_outline" data-bs-toggle="modal"
                                            data-bs-target="#exampleModal">
                                            <svg xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31"
                                                viewBox="0 0 31 31">
                                                <circle id="bg" cx="15.5" cy="15.5" r="15.5" fill="#ff9500"></circle>
                                                <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff"
                                                    stroke-linejoin="round" stroke-miterlimit="10">
                                                    <path
                                                        d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z"
                                                        stroke="none"></path>
                                                    <path
                                                        d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z"
                                                        stroke="none" fill="#fff"></path>
                                                </g>
                                            </svg>
                                            Watch Video Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center down_to_section newarrow">

                <a v-scroll-to="{
                        el: '#claim_space',
                        duration: 500,
                        easing: 'linear',
                        offset: -350,
                    }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50.132" height="35.219" viewBox="0 0 57.132 61.219">
                        <g id="Down_arrow" data-name="Down arrow" transform="translate(-20020.894 -12891.354) rotate(90)">
                            <path id="Path_30" data-name="Path 30" d="M1744.937,353.578l24.648,24.591-24.7,24.763"
                                transform="translate(11180.239 -20427.715)" fill="none" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="5.5" />
                            <path id="Path_31" data-name="Path 31" d="M1744.937,353.578l24.648,24.591-24.7,24.763"
                                transform="translate(11150.357 -20427.715)" fill="none" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="5.5" />
                        </g>
                    </svg>
                </a>
            </div>
        </section>
        <!-- image text end -->
        <!-- Claim Your Space start -->
        <section id="claim_space" class="claim_space_sec py_100 my_14">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-11 col-xl-9 col-12">
                        <div class="claim_box d-flex">
                            <div class="claim_text_image_box">
                                <h4 class="text_primary font_bold font_size_32 mb_26"> Setup
                                    <br> guidelines
                                </h4>
                                <img src="../assets/images/setup_guidline.png" alt="img">
                            </div>
                            <div class="claim_text_box">
                                <p class="text_black font_bold mb_44">
                                    <strong>Bench Work </strong>
                                </p>
                                <ul>
                                    <li>
                                        <p class="p_24"><strong>Set your work at elbow height</strong> and keep your
                                            items close to you.</p>
                                        <ul class="list-unstyled my_16">
                                            <li>
                                                <p>- Precision Work = slightly above elbow height</p>
                                            </li>
                                            <li>
                                                <p>- Light Work = slightly below elbow height</p>
                                            </li>
                                            <li>
                                                <p>- Heavy Work = approximately 6” lower than elbow height</p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="mb_16">
                                        <p><strong>Adjust your seat height,</strong> backrest and foot ring for optimal
                                            support. (Remember, you can stand too!).</p>
                                    </li>
                                    <li class="mb_16">
                                        <p><strong>Make sure there is clearance for your legs.</strong> If possible
                                            remove drawers and doors and be sure to clear clutter in cut out area.</p>
                                    </li>
                                    <li class="mb_16">
                                        <p><strong>Store heavier equipment</strong> on lower shelves or on the floor.
                                        </p>
                                    </li>
                                    <li>
                                        <p><strong>Choose equipment</strong> that helps to minimize your grip pressure
                                            and force.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="claim_box d-flex">
                            <div class="claim_text_image_box">
                                <h4 class="text_primary font_bold font_size_32 mb_26"> Technique
                                    <br> tips
                                </h4>
                                <img src="../assets/images/st2.png" alt="img">
                            </div>
                            <div class="claim_text_box">
                                <p>Whether you sit or stand when you work, be mindful of your posture and try to avoid
                                    slouching. Try bringing your items up to eye level to keep your neck from bending
                                    too far forward when doing your work.
                                </p>
                                <p>It is also very important to pay attention to how you are using your hands and arms
                                    when doing bench work. Strive to maintain your wrists in a neutral position and
                                    relax your grip and avoid pinching when handling your instruments. Lastly, remember
                                    to take frequent breaks. Your body will thank you!


                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Claim Your Space end -->
        <!-- more WFH tips start  -->
        <section class="more_lab_tips bg_grey pt_42">
            <div class="container">
                <div class="row justify-content-center ">
                    <div class="col-md-12 col-xl-11 px_20">
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="heading_4 font_bold mb-0">More lab tips</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-xl-4">
                                <router-link :to="{ name: 'bench_work' }" class="more_lab_tip_link">
                                    <div class="d-flex setup-guide">
                                        <div class="flex-shrink-0 media-left">
                                            <img src="../assets/images/work_from_lab/lab-1.png" alt="img" class="img-fluid">
                                        </div>
                                        <div class="flex-grow-1 ml_20 media-right">
                                            <h6 class="font_size_22 text_black mb_10">Bench Work </h6>
                                            <p>When done right, bench work is a breeze. Learn more. </p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-sm-12 col-md-6 col-xl-4">
                                <router-link :to="{ name: 'pipetting' }" class="more_lab_tip_link">
                                    <div class="d-flex setup-guide">
                                        <div class="flex-shrink-0 media-left">
                                            <img src="../assets/images/work_from_lab/lab-2.png" alt="img" class="img-fluid">
                                        </div>
                                        <div class="flex-grow-1 ml_20 media-right">
                                            <h6 class="font_size_22 text_black mb_10">Pipetting</h6>
                                            <p>Side step pipetting woes and learn how to feel great. </p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-sm-12 col-md-6 col-xl-4">
                                <router-link :to="{ name: 'microscope' }" class="more_lab_tip_link">
                                    <div class="d-flex setup-guide">
                                        <div class="flex-shrink-0 media-left">
                                            <img src="../assets/images/work_from_lab/lab-3.png" alt="img" class="img-fluid">
                                        </div>
                                        <div class="flex-grow-1 ml_20 media-right">
                                            <h6 class="font_size_22 text_black mb_10">Microscopes</h6>
                                            <p>Your eyes, back and neck will rejoice after learning these tips. </p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-sm-12 col-md-6 col-xl-4">
                                <router-link :to="{ name: 'material_handling' }" class="more_lab_tip_link">
                                    <div class="d-flex setup-guide">
                                        <div class="flex-shrink-0 media-left">
                                            <img src="../assets/images/work_from_lab/lab-4.png" alt="img" class="img-fluid">
                                        </div>
                                        <div class="flex-grow-1 ml_20 media-right">
                                            <h6 class="font_size_22 text_black mb_10">Material Handling</h6>
                                            <p>Whether you are bending or lifting, we’ve got your back. </p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-sm-12 col-md-6 col-xl-4">
                                <router-link :to="{ name: 'hood_work' }" class="more_lab_tip_link">
                                    <div class="d-flex setup-guide">
                                        <div class="flex-shrink-0 media-left">
                                            <img src="../assets/images/work_from_lab/lab-5.png" alt="img" class="img-fluid">
                                        </div>
                                        <div class="flex-grow-1 ml_20 media-right">
                                            <h6 class="font_size_22 text_black mb_10">Hood Work</h6>
                                            <p>No more awkward positions when doing your hood work. </p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-sm-12 col-md-6 col-xl-4">
                                <router-link :to="{ name: 'lab_stretches' }" class="more_lab_tip_link">
                                    <div class="d-flex setup-guide">
                                        <div class="flex-shrink-0 media-left">
                                            <img src="../assets/images/work_from_lab/lab-6.png" alt="img" class="img-fluid">
                                        </div>
                                        <div class="flex-grow-1 ml_20 media-right">
                                            <h6 class="font_size_22 text_black mb_10">Lab Stretches</h6>
                                            <p>Combat awkward postures and work positions with these stretches. </p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- more WFH tips end  -->
        <!-- Video Modal start Here -->
        <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="text-end">
                            <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
                                @click="hideModal"><img src="../assets/images/close_modal.png"></a>
                        </div>
                    <div class="video_elements">
                        <iframe id="closed5"
                            src="https://player.vimeo.com/video/23023409?h=6ca18a2dee&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                            style="position:absolute;top:0;left:0;width:100%;height:100%;"
                            title="Laboratory Ergonomics"></iframe>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Video Modal End Here -->
</div></template>

<script>
export default {
    name: "bench_work",
    mounted() {
        document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    },
    methods: {
        hideModal() {
            document.getElementById('closed5').src += '';
        },
    }
}
</script>